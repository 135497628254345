
import { useState } from "react";

const Button = ({ type, onClick, className, children, arrow, arrowCss, hoverCss }) => {
  const defaultClass = "md:px-[20px] px-[10px] py-[15px] rounded-[10px] md:gap-[10px] gap-0 flex transition ease duration-75";
  const [isHovered, setIsHovered] = useState(false);
     

    const handleButtonHover = () => {
        setIsHovered(true);
      };
    const handleButtonLeave = () => {
        setIsHovered(false);
      };


  return (
    <button className={`${defaultClass} ${className} `} onClick={onClick} onMouseEnter={handleButtonHover} onMouseLeave={handleButtonLeave} type={type}>
      {children}
      <div>
        {arrow && (
          <div className={`${arrowCss} px-[10px] rounded-[5px] hidden md:flex ${isHovered ? hoverCss : ""}`}>
            <img src={"/asset/arrow.svg"} width={24} height={24} alt="arrow" />
          </div>
        )}
      </div>
    </button>
  );
};

export default Button;
