import DiscoverHero from "../components/discover/DiscoverHero";
import { projectData } from "../constants";
import ProjectCard from "../components/global/ProjectCard";
import React from "react";

const Discover = () => {
  return (
    <section>
      <div className="paddingX ">
        <div className="boxWidth">
          <DiscoverHero />
        </div>
      </div>
      <div className="paddingX paddingY">
        <div className="boxWidth">
          <h2 className="md:text-[36px] text-[24px]">Discover Inspirations</h2>
        </div>
      </div>
      <div className="paddingX pb-10">
        <div className="boxWidth">
          <div className="projects-grid">
          {projectData.map((item) => (
            <ProjectCard
              userName={item.name}
              views={item.view}
              likes={item.likes}
              title={item.title}
              user={true}
            />
          ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discover;
