import React from "react";
import FormField from "../global/FormField";
import SmButton from "../global/SmButton";


const EditForm = () => {
  return (
    <form className="flexStart form">
      <div className="flexStart form_image-container">
        <label htmlFor="poster" className="flexCenter form_image-label">
          {"Upload profile pic"}
        </label>
        <input
          id="image"
          type="file"
          accept="image/*"
          className="form_image-input"
          onChange={(e) => {}}
        />
        {/* {form.image && (
          <video
            src={form?.image}
            className="sm:p-10 object-contain z-20"
            alt="image"
            fill
          />
        )} */}
      </div>

      <FormField
        title="Name"
        placeholder="Mark"
        setState={(value) => {}}
      />

      <FormField
        title="About"
        placeholder="Showcase and discover remarkable developer projects."
        isTextArea
        setState={(value) => {}}
      />

      <FormField
        title="Country"
        placeholder="Canada"
        setState={(value) => {}}
      />
      <FormField
        title="Instagram"
        placeholder="https://instagram.com"
        setState={(value) => {}}
      />
      <FormField
        title="Linkedin"
        placeholder="https://linkedin.com"
        setState={(value) => {}}
      />
      <FormField
        title="Facebook"
        placeholder="https://facebook.com"
        setState={(value) => {}}
      />
      <FormField
        title="Twitter"
        placeholder="https://twitter.com"
        setState={(value) => {}}
      />

      <div className="flexStart w-full">
        <SmButton
          className={
            "bg-light-blue flex gap-3 items-center hover:bg-dark-blue transition ease duration-75"
          }
        >
          <img src="/asset/add.svg" alt="" />
          Update
        </SmButton>
      </div>
    </form>
  );
};

export default EditForm;
