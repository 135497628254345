import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  CreateProject,
  Discover,
  EditProject,
  Hire,
  Home,
  Profile,
  ProfileEdit,
  Login,
  Signup,
  Jobs
} from './pages'
import Navbar from "./components/global/Navbar";
import Footer from "./components/global/Footer";

function App() {
  return (
    <div className="App">
      <div className='paddingX'>
        <div className='boxWidth'>
          <Navbar />
        </div>
      </div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/create-project' element={<CreateProject />} />
        <Route path='/discover' element={<Discover />} />
        <Route path='/edit-project' element={<EditProject />} />
        <Route path='/hire' element={<Hire />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/profile-edit' element={<ProfileEdit />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/jobs' element={<Jobs />} />
      </Routes>
      <div className='paddingX'>
        <div className='boxWidth'>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
