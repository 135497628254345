import React from "react";
import VideoBackground from "../global/VideoBackground";
import Button from "../global/Button";

const Hero = () => {
  const wordsArray = ["Video editors", "Filmmakers", "Videographers"];
  const colorClass = ["text-primary"];

  return (
    <div className="py-6 flex flex-col gap-[10px]">
      <VideoBackground
        src={"/asset/video.webm"}
        className={"w-full md:rounded-[50px] rounded-[20px] relative"}
        title={"The number 1 community for "}
        wordsArray={wordsArray}
        colorClass={colorClass}
        animation={true}
      />
      <div className="flex flex-col md:hidden ">
        <Button
          className={
            "bg-light-blue hover:bg-dark-blue w-full items-center justify-center"
          }
          arrow={true}
          arrowCss={"bg-dark_blue"}
          hoverCss={"hover:bg-primary"}
        >
          Join for free
        </Button>
      </div>
    </div>
  );
};

export default Hero;
