import React from "react";
import VideoBackground from "../global/VideoBackground";
import Button from "../global/Button";

const Subscribe = () => {
  return (
    <div className="flex gap-[30px] flex-col">
      <div className="py-6 flex flex-col gap-[10px]">
        <VideoBackground
          src={"/asset/video1.webm"}
          className={"w-full md:rounded-[50px] rounded-[20px] relative"}
          title={"Subscribe to Newsletter"}
          para={"Ready to join this platform "}
          inputSub={true}
        />
        <div>
          <form
            // onSubmit={sendMail}
            className="flex flex-col md:hidden gap-[10px]"
          >
            <input
              type="email"
              placeholder="Enter your email"
              className="px-[15px] py-[15px] rounded-[10px] w-full bg-primary border border-dark-grey outline-none"
              // value={subject}
              // onChange={(e) => {
              //   setSubject(e.target.value);
              // }}
              // required
            />
            <Button
              className="bg-light-blue hover:bg-dark-blue w-full items-center justify-center"
              arrow={true}
              // arrowCss={"bg-light-blue"}
              // hoverCss={"hover:bg-dark-blue"}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
      <div className="h-[1px] w-full bg-dark mb-4" />
    </div>
  );
};

export default Subscribe;
