import React from 'react'
import ServiceDesc from '../global/ServiceDesc'

const Services = () => {
  return (
    <div className='py-6 flex flex-col gap-[30px]'>
        <div>
            <h1 className='text-center sm:text-[64px] text-[34px]'>Services We provide</h1>
        </div>
        <div className='h-[1px] w-full bg-dark mb-4'/>
        <div className='flex flex-col gap-[30px]'>
        <ServiceDesc
            src={'/asset/learn.png'}
            title={'Learn'}
            para={'Get inspiration and learn new skills and techniques from fellow video creators.'}
            direction={true}
        />
        <ServiceDesc
            src={'/asset/portfolio.png'}
            title={'Build your portfolio'}
            para={'Showcase your masterpieces for the world to see.'}
            direction={false}
        />
        <ServiceDesc
            src={'/asset/work.png'}
            title={'Find work'}
            para={'Browse and apply for short- and long-term job opportunities on our exclusive job board for video creators.'}
            direction={true}
        />
        </div>
    </div>
  )
}

export default Services