import React from "react";
import SmMenu from "./SmMenu";
import ProfileMenu from "./ProfileMenu";

const Navbar = () => {
  const session = true;

  return (
    <nav className="flex justify-between items-center py-6">
      <div className="flex items-center gap-[30px]">
        <div>
          <a href={"/"}>
            <h4 className="text-[36px] ">FrameFlow</h4>
          </a>
        </div>
        <div className="md:flex hidden">
          <ul className="flex gap-[30px]">
            <a href={"/discover"} className="hoverLinks">
              Discover
            </a>
            <a href={"/jobs"} className="hoverLinks">
              Jobs
            </a>
            <a href={"/hire"} className="hoverLinks">
              Hire
            </a>
          </ul>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flexCenter gap-4">
          {session ? (
            <>
              <div className="md:flex hidden gap-3">
                <ProfileMenu />
                <a
                  href="/create-project"
                  className="flex gap-2 items-center bg-light-blue px-[15px] py-[10px] rounded-[10px]"
                >
                  <img src="/asset/add.svg" alt="" className="md:flex hidden" />
                  <p>Share Work</p>
                </a>
              </div>
            </>
          ) : (
            <>AuthProvider</>
          )}
        </div>
        <div className="flex md:hidden">
          <SmMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
