import React from "react";

const Search = () => {
  return (
    <form className="flex items-center bg-primary md:w-[700px] w-full justify-between p-[20px] rounded-[10px]">
      <input
        type="text"
        placeholder="Search..."
        className="bg-transparent w-full outline-none"
      />
      <button>
        <img src={"/asset/Search.svg"} width={24} height={24} alt="search" />
      </button>
    </form>
  );
};

export default Search;
