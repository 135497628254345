
import React from "react";

const ServiceDesc = ({ src, title, para, direction, alt }) => {
  return (
    <div>
      {direction ? (
        <div className="flex gap-[10px] flex-wrap items-center justify-between">
          <img
            src={src}
            width={625}
            height={448}
            alt={alt}
            className="md:rounded-[50px] rounded-[20px] lg:w-[625px] w-full"
          />
          <div className="flex flex-col gap-[10px]">
            <h1 className="sm:text-[82px] text-[48px] md:w-[450px] w-full">{title}</h1>
            <p className="md:w-[400px] w-full">{para}</p>
          </div>
        </div>
      ) : (
        <div className=" gap-[10px] flex flex-wrap-reverse items-center justify-between">
          <div className="flex flex-col gap-[10px]">
            <h1 className="sm:text-[82px] text-[48px] md:w-[450px] w-full">{title}</h1>
            <p className="md:w-[400px] w-full">{para}</p>
          </div>
          <img
            src={src}
            width={625}
            height={448}
            alt={alt}
            className="md:rounded-[50px] rounded-[20px] lg:w-[625px] w-full"
          />
        </div>
      )}
    </div>
  );
};

export default ServiceDesc;
