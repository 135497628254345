import React from "react";
import { Hero, Services, Viewers, Subscribe } from "../components/home";

const Home = () => {
  return (
    <main>
      <div className="paddingX ">
        <div className="boxWidth">
          <Hero />
        </div>
      </div>
      <div className="paddingX ">
        <div className="boxWidth">
          <Services />
        </div>
      </div>
      <div className="paddingX py-6">
        <div className="boxWidth">
          <Viewers />
        </div>
      </div>
      <div className="paddingX py-6">
        <div className="boxWidth">
          <Subscribe />
        </div>
      </div>
    </main>
  );
};

export default Home;
