
import React, { useEffect, useRef, useState } from "react";
import Search from "../global/Search";

const SearchHero = ({ src, title, para, className, children, inputSub }) => {
  const videoRef = useRef();

  return (
    <div className="relative">
      <video
        src={src}
        className={`w-full h-auto ${className}`}
        ref={videoRef}
        loop
        muted
        playsInline
        autoPlay
      >
        {children}
      </video>
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white md:gap-[40px] gap-[10px]">
        <h1 className="lg:w-[750px] w-full lg:text-[48px] text-[24px] font-medium tracking-tighter text-center">
          {`${title}`}
        </h1>
        <p className="md:text-[16px] text-[14px] text-center">{para}</p>
        <div className="md:flex hidden">
          <Search />
        </div>
      </div>
    </div>
  );
};

export default SearchHero;
