import React from "react";
import SmButton from "../global/SmButton";
import { projectData, userCardData } from "../../constants";
import ProjectCard from "../global/ProjectCard";

const UserCard = () => {
  return (
    <div className="flex flex-col gap-8">
        {userCardData.map((item) => (
        <div>
      <div className="flex justify-between items-center ">
        <div className="flex items-center gap-8">
          <img
            src="/asset/learn.png"
            alt=""
            className="w-[100px] h-[100px] rounded-full"
          />
          <div className="flex flex-col gap-5">
            <a href="/profile">
              <h4 className="text-[24px]">Halo UI/UX</h4>
            </a>
            <p className="text-para">Country</p>
          </div>
        </div>
        <div>
          <SmButton
            className={
              "bg-light-blue hover:bg-dark-blue transition ease duration-75"
            }
          >
            Message
          </SmButton>
        </div>
      </div>
      <div className="boxWidth">
        <div className="projects-grid">
          {projectData.map((item) => (
            <ProjectCard
              title={item.title}
            />
          ))}
        </div>
      </div>
    </div>
    ) )}
    </div>
  );
};

export default UserCard;
