import React from "react";
import Modal from "../components/global/Modal";
import EditForm from '../components/profile-edit/EditForm';

const ProfileEdit = () => {
  return (
    <Modal page={'edit'}>
      <h3 className="modal-head-text">Edit your Profile</h3>
      <EditForm />
    </Modal>
  );
};

export default ProfileEdit;
