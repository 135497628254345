import React from "react";
import SmButton from "../global/SmButton";

const JobHero = () => {
  return (
    <div className="flex flex-col items-center gap-[30px]">
      <div className="flex flex-col gap-[20px]">
        <h1 className="text-[48px] text-center md:w-[500px] w-full">
          The #1 job board for graphic design jobs
        </h1>
        <p className="text-para text-center md:w-[500px] w-full">
          Vribble is the heart of the design community and the best resource to
          discover and connect with designers and jobs worldwide
        </p>
      </div>
      <div className="flex gap-[10px]">
        <SmButton className={'bg-white hover:bg-slate-200 transition ease duration-75 text-primary'}>
          Post a Job
        </SmButton>
        <SmButton className={' text-para hover:text-light-blue transition ease duration-75'}>
          Learn More
        </SmButton>
        
      </div>
    </div>
  );
};

export default JobHero;
