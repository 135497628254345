
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";


const ProfileMenu = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="flexCenter z-10 flex-col relative">
            <Menu as="div">
                <Menu.Button className="flexCenter" onMouseEnter={() => setOpenModal(true)} >
                        <img
                            src={'/asset/img.png'}
                            width={40}
                            height={40}
                            className="rounded-full"
                            alt="user profile img"
                        />
                </Menu.Button>

                <Transition
                    show={openModal}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        static
                        className="flexStart profile_menu-items"
                        onMouseLeave={() => setOpenModal(false)}
                    >
                        <div className="flex flex-col items-center gap-y-4">
                           
                                <img
                                    src={'/asset/img.png'}
                                    className="rounded-full w-[80px] h-[80px]"
                                    width={80}
                                    height={80}
                                    alt="profile img"
                                />
                         
                            <p className="font-semibold">Nauman</p>
                        </div>

                        <div className="flex flex-col gap-3 pt-10 items-center w-full">
                            <Menu.Item>
                                <a href={`/profile`} className="text-sm">Profile</a>
                            </Menu.Item>
                        </div>
                        <div className="w-full flexCenter border-t border-nav-border mt-5 pt-5">
                            <Menu.Item>
                                <button type="button" className="text-sm" onClick={() => {}}>
                                    Sign out
                                </button>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default ProfileMenu