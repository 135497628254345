import React, { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you are using React Router


const Modal = ({ children, page }) => {
  const overlay = useRef(null);
  const wrapper = useRef(null);
  const navigate = useNavigate();

  const onDismiss = useCallback(() => {
    if (page === 'edit') {
      navigate('/profile');
    } else {
      navigate('/');
    }
   
  }, [navigate]);

  const handleClick = useCallback((e) => {
    if (e.target === overlay.current && onDismiss) {
      onDismiss();
    }
  }, [onDismiss, overlay]);

  return (
    <div ref={overlay} className="modal" onClick={handleClick}>
      <button type="button" onClick={onDismiss} className="absolute top-4 right-8">
        <img src="/asset/close.svg" width={17} height={17} alt="close" />
      </button>
      <div ref={wrapper} className="modal_wrapper">
        {children}
      </div>
    </div>
  );
};

export default Modal;
