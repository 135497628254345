
import React from "react";

const Footer = () => {
  return (
    <footer className="py-6 grid md:grid-cols-3 grid-cols-1 w-full md:gap-[0] gap-[20px] border-t border-dark-grey">
      <a href={'/'}>
        <h2 className="md:text-start text-center">FrameFlow</h2>
      </a>
      <ul className="flex gap-[30px] justify-center">
        <a href={"/"} className="hoveras">
          Discover
        </a>
        <a href={"/"} className="hoveras">
          Jobs
        </a>
        <a href={"/"} className="hoveras">
          Hire
        </a>
      </ul>
      <div className="flex gap-[30px] md:justify-end justify-center">
        <a href={"/"} className="">
          <img src={"/asset/facebook.svg"} width={16} height={16} alt="icons" />
        </a>
        <a href={"/"} className="">
          <img src={"/asset/twitter.svg"} width={16} height={16} alt="icons" />
        </a>
        <a href={"/"} className="">
          <img src={"/asset/insta.svg"} width={16} height={16} alt="icons" />
        </a>
        <a href={"/"} className="">
          <img src={"/asset/linkedin.svg"} width={16} height={16} alt="icons" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
