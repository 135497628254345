

export const projectData = [
    {
        // image: {img},
        avatar: '',
        name: 'morrerre.store',
        title: 'Youtube thumbnails',
        view: '1.2k',
        likes: '345'
    },
    {
        // image: {img},
        avatar: '',
        name: 'morrerre.store',
        title: 'Youtube thumbnails',
        view: '1.2k',
        likes: '345'
    },
    {
        // image: {img},
        avatar: '',
        name: 'morrerre.store',
        title: 'Youtube thumbnails',
        view: '1.2k',
        likes: '345'
    },
    {
        // image: {img},
        avatar: '',
        name: 'morrerre.store',
        title: 'Youtube thumbnails',
        view: '1.2k',
        likes: '345'
    },
]
export const jobData = [
    {
        // image: {img},
        name: 'morrerre.store',
        title: 'Product / Brand Designer For Launch',
        JobType: 'Remote',
        Posted: 'Posted 5 hours ago'
    },
    {
        // image: {img},
        name: 'morrerre.store',
        title: 'Product / Brand Designer For Launch',
        JobType: 'Remote',
        Posted: 'Posted 5 hours ago'
    },
]

export const userCardData = [
    {
        name: 'Halo UI/UX',
        country: 'Canada',

    },
    {
        name: 'Halo UI/UX',
        country: 'Canada',

    },
]

export const userDetail = [
    {
        name: 'Lukas Majzan',
        country: 'Canada',
        views: '627,721',
        likes: '43,143',
        about: 'Founder of Art4web, Web/UX/UI Designer, Awwwards Jury Art4web'

    },
]