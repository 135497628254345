import React from "react";
import UserDetail from "../components/profile/UserDetail";
import ProjectCard from "../components/global/ProjectCard";
import { projectData } from "../constants";

const Profile = () => {
  return (
    <main className="paddingX paddingY flex flex-col gap-10">
      <div className="boxWidth">
        <UserDetail />
      </div>
      <div className="flex flex-col gap-6">
        <p className="text-start">Projects by Lukas Majzlan</p>
        <div className="boxWidth">
          <div className="projects-grid">
          {projectData.map((item) => (
            <ProjectCard
              userName={item.name}
              views={item.view}
              likes={item.likes}
              title={item.title}
              user={true}
            />
          ))}
          </div>
          </div>
      </div>
    </main>
  );
};

export default Profile;
