import React from "react";

const ProjectCard = ({ img, userName, likes, views, title, user }) => {
  return (
    <div className="flexCenter flex-col rounded-2xl drop-shadow-card">
      <a href={`/project`} className="flexCenter group relative w-full h-full">
        <img
          src={"/asset/img.png"}
          width={414}
          height={314}
          className="w-full h-full object-cover "
          alt="project img"
        />

        <div className="hidden group-hover:flex profile_card-title">
          <p className="w-full">{title}</p>
        </div>
      </a>

      {user && (
        <div className="flexBetween w-full px-2 mt-3 font-semibold text-sm">
          <a href={`/profile`}>
            <div className="flexCenter gap-2">
              <img
                src={"/asset/img.png"}
                width={24}
                height={24}
                className="rounded-full"
                alt="profile img"
              />
              <p>{userName}</p>
            </div>
          </a>

          <div className="flexCenter gap-3">
            <div className="flexCenter gap-2">
              <img src="/asset/like.svg" width={12} height={12} alt="heart" />
              <p className="text-[12px]">{likes}</p>
            </div>
            <div className="flexCenter gap-2">
              <img src="/asset/eye.svg" width={12} height={12} alt="eye" />
              <p className="text-[12px]">{views}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
