import React from 'react'
import Modal from '../components/global/Modal'
import ProjectForm from '../components/create-project/ProjectForm'

const CreateProject = () => {
  return (
    
    <Modal>
      <h3 className="modal-head-text">
            Create a new Project
        </h3>
        <ProjectForm />
    </Modal>
  )
}

export default CreateProject