import React from 'react'

const DisclosuresSec = ({ title, para }) => {
  return (
    <div className='flex flex-col px-[30px] py-[40px] rounded-[20px] bg-dark-grey hover:bg-dark-blue transition ease duration-75 gap-[20px]'>
        <h4 className='text-[24px] font-medium text-white'>{title}</h4>
        <p>{para}</p>
    </div>
  )
}

export default DisclosuresSec