import React from "react";
import FormField from "../global/FormField";
import SmButton from "../global/SmButton";

const ProjectForm = () => {
  return (
    <form className="flexStart form">
      <div className="flexStart form_image-container">
        <label htmlFor="poster" className="flexCenter form_image-label">
          {"Choose a poster for your project"}
        </label>
        <input
          id="image"
          type="file"
          accept="image/*"
          className="form_image-input"
          onChange={(e) => {}}
        />
        {/* {form.image && (
          <video
            src={form?.image}
            className="sm:p-10 object-contain z-20"
            alt="image"
            fill
          />
        )} */}
      </div>

      <FormField
        title="Title"
        placeholder="Flexibble"
        setState={(value) => {}}
      />

      <FormField
        title="Description"
        placeholder="Showcase and discover remarkable developer projects."
        isTextArea
        setState={(value) => {}}
      />

      

      {/* <CustomMenu
        title="Category"
        state={form.category}
        filters={categoryFilters}
        setState={(value) => handleStateChange("category", value)}
      /> */}

      <div className="flexStart w-full">
        <SmButton
        className={'bg-light-blue flex gap-3 items-center hover:bg-dark-blue transition ease duration-75'}
        >
            <img src="/asset/add.svg" alt="" />
            Create</SmButton>
      </div>
    </form>
  );
};

export default ProjectForm;
