import React from 'react'

const SmButton = ({ className, children, onClick }) => {
    const defaultClassName = "p-[15px] rounded-[10px]";

  return (
    <button className={`${className} ${defaultClassName}`} onClick={onClick}>
        {children}
    </button>
  )
}

export default SmButton