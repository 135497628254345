import SearchHero from "./SearchHero";
import Search from "../global/Search";

const DiscoverHero = () => {
  return (
    <div className="py-6 flex flex-col gap-[10px]">
      <SearchHero
        src={"/asset/video.webm"}
        className={"w-full md:rounded-[50px] rounded-[20px] relative"}
        title={
          "The best free inspirational videos shared by the vribble community"
        }
      />
      <div className="flex flex-col md:hidden ">
        <Search />
      </div>
    </div>
  );
};

export default DiscoverHero;
