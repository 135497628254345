import React from "react";
import UserCard from "../components/hire/UserCard";

const Hire = () => {
  return (
    <section className="paddingX paddingY">
      <div className="bowidth">
        <div className="flex flex-col items-center gap-[30px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[48px] text-center md:w-[500px] w-full">
              Designers for Hire
            </h1>
            <p className="text-para text-center md:w-[500px] w-full">
              Find the world’s nest video editor on vribble - the largest
              independent community for editors
            </p>
          </div>
        </div>
      </div>
      <div className="boxwidth">
        <div className="py-10 flex flex-col gap-5">
          <h2 className="md:text-[36px] text-[24px]">Hire a Talent</h2>
          <UserCard/>
          {/* {jobData.map((job) => (
            <JobCard
              name={job.name}
              title={job.title}
              jobType={job.JobType}
              Posted={job.Posted}
              // image={job.image}
            />
          ))} */}
        </div>
      </div>
    </section>
  );
};

export default Hire;
