import React from "react";
import JobHero from "../components/jobs/JobHero";
import JobCard from "../components/jobs/JobCard";
import { jobData } from "../constants";

const Jobs = () => {
  return (
    <section className="paddingX paddingY">
      <div className="boxwidth">
        <JobHero />
      </div>
      <div className="boxwidth">
        <div className="py-10 flex flex-col gap-5">
          <h2 className="md:text-[36px] text-[24px]">Posted Jobs</h2>
          {jobData.map((job) => (
            <JobCard
              name={job.name}
              title={job.title}
              jobType={job.JobType}
              Posted={job.Posted}
              // image={job.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Jobs;
