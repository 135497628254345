import { useState } from "react";
import { Squeeze as Hamburger } from "hamburger-react";

const SmMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="md:hidden flex z-10">
      <Hamburger
        toggled={isOpen}
        toggle={setIsOpen}
        size={20}
        color="#8698AB"
      />
      {isOpen && (
        <div className="md:hidden flex absolute top-[100px] w-full left-0 bg-primary">
          <ul className="flex flex-col gap-[30px] px-6 py-6">
            <a href={"/discover"} className="hoverLinks" onClick={handleClick}>
              Discover
            </a>
            <a href={"/jobs"} className="hoverLinks" onClick={handleClick}>
              Jobs
            </a>
            <a href={"/hire"} className="hoverLinks" onClick={handleClick}>
              Hire
            </a>
            <a href={"/profile"} className="hoverLinks" onClick={handleClick}>
              Profile
            </a>
            <a
              href="/create-project"
              className="flex gap-2 items-center bg-light-blue px-[15px] py-[10px] rounded-[10px] w-full"
            >
              <img src="/asset/add.svg" alt="" className="md:flex hidden" />
              <p>Share Work</p>
            </a>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SmMenu;
