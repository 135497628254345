
import React from "react";

const JobCard = ({ image, name, title, jobType, Posted }) => {
  return (
    <div className="flex justify-between px-[30px] py-[25px] bg-dark-grey rounded-[10px] items-center">
      <div className="flex gap-5 items-center">
        <div>
          <img src={'/asset/img.png'} width={90} height={90} alt="picture" className="rounded-[10px]"/>
        </div>
        <div className="flex flex-col gap-2 items-start">
            <p className="text-para text-[14px]">{name}</p>
            <h4 className="font-medium">{title}</h4>
        </div>
      </div>
      <div className="flex flex-col items-end gap-2">
        <div className="flex gap-2 text-para text-[14px]">
            <img src={'/asset/Map.svg'} alt="map" width={16} height={16} />
            {jobType}
        </div>
        <p className="text-[14px] text-para">{Posted}</p>
      </div>
    </div>
  );
};

export default JobCard;
