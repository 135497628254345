
import React, { useEffect, useRef, useState } from "react";
import Button from "../global/Button";

const VideoBackground = ({
  src,
  title,
  para,
  className,
  children,
  inputSub,
  wordsArray = [],
  animation
}) => {
  const videoRef = useRef();
  const [subject, setSubject] = useState("");
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const currentWord = wordsArray && wordsArray.length > 0 ? wordsArray[currentWordIndex] : "";
  const [animatedText, setAnimatedText] = useState("");
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const videoElement = videoRef.current;

    const playVideo = () => {
      videoElement.play().catch((error) => {
        console.error("Video playback error:", error);
      });
    };

    playVideo();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTyping) {
        if (animatedText.length < currentWord.length) {
          setAnimatedText(currentWord.slice(0, animatedText.length + 1));
        } else {
          setTimeout(() => setIsTyping(false), 1000);
        }
      } else {
        if (animatedText.length > 0) {
          setAnimatedText(animatedText.slice(0, animatedText.length - 1));
        } else {
          setIsTyping(true);
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % wordsArray.length);
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [currentWord, animatedText, isTyping, wordsArray]);

  const titleWithoutLastWord = title.split(" ").slice(0, -1).join(" ");
  const animatedTitle = `${titleWithoutLastWord} ${animatedText}`;

  const sendMail = async (e) => {
    e.preventDefault();

    const response = await fetch("/api/sendEmail", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        subject,
      }),
    });

    console.log(await response.json());
  };

  return (
    <div className="relative">
      <video
        src={src}
        className={`w-full h-auto ${className}`}
        ref={videoRef}
        loop
        muted
        playsInline
        autoPlay
      >
        {children}
      </video>
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white md:gap-[40px] gap-[10px]">
        <h1 className="lg:text-[48px] text-[24px] font-medium tracking-tighter text-center">
          {animation ? `${animatedTitle}`: `${title}`}
        </h1>
        <p className="md:text-[16px] text-[14px] text-center">{para}</p>
        <div className="md:flex hidden">
          {inputSub ? (
            <form onSubmit={sendMail} className="md:flex hidden gap-[10px]">
              <input
                type="text"
                placeholder="Enter you email"
                className="px-[15px] rounded-[10px] w-[400px] bg-primary outline-none"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                required
              />
              <Button
                className={"bg-primary hover:bg-light-blue"}
                arrow={true}
                arrowCss={"bg-white"}
                hoverCss={"hover:bg-dark-blue"}
                type="submit"
              >
                Join for free
              </Button>
            </form>
          ) : (
            <Button
              className={"bg-primary hover:bg-light-blue"}
              arrow={true}
              arrowCss={"bg-white"}
              hoverCss={"hover:bg-light-blue"}
            >
              Join for free
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;
