import DisclosuresSec from "../global/DisclosureSec";

const ViewersSec = () => {
  return (
    <div className="py-6 flex flex-col gap-[30px]">
      <div>
        <p className="text-center text-primary">Elevating Your Entertainment Experience</p>
        <h1 className='text-center sm:text-[64px] text-[34px]'>Viewers' Concierge</h1>
      </div>
      <div className='h-[1px] w-full bg-dark mb-4'/>
      <div className="flex w-full justify-between items-center flex-wrap md:flex-row flex-col">
        <div className="flex flex-col gap-[10px] lg:justify-start justify-center">
          <DisclosuresSec
            title={"Discover"}
            para={
              "View the work of thousands of video creators around the world."
            }
          />
          <DisclosuresSec
            title={"Engage and share"}
            para={"Comment on and share work within the community."}
          />
          <DisclosuresSec
            title={"Hire talent"}
            para={"Post jobs and hire the best video talent in the world."}
          />
        </div>
        <img
          src={"/asset/img1.png"}
          width={576}
          height={647}
          alt="img"
          className="lg:w-[576px] w-full"
        />
      </div>
    </div>
  );
};

export default ViewersSec;
