import React from "react";
import { userDetail } from "../../constants";
import SmButton from "../global/SmButton";

const UserDetail = () => {
  const session = true;

  return (
    <section className="flexCenter flex-col gap-4">
      <img
        src="/asset/img.png"
        alt=""
        className="rounded-full w-[176px] h-[176px] border border-white"
      />
      {userDetail.map((item) => (
        <div className="flexCenter flex-col gap-7 ">
          <h2 className="md:text-[48px] text-[24px]">{item.name}</h2>
          {session && (
            <a href="/profile-edit">
              <button className="px-[20px] py-[10px] bg-primary rounded-[10px] hover:bg-dark-grey transition ease duration-75">
                edit
              </button>
            </a>
          )}
          <div className="flexCenter md:flex-row flex-col gap-3 text-para">
            <p>{item.country}</p>
            <div className="w-[9px] h-[9px] rounded-full bg-para" />
            <p>{item.views} Project views</p>
            <div className="w-[9px] h-[9px] rounded-full bg-para" />
            <p>{item.likes} Appreciation</p>
          </div>
          <p className="text-center">{item.about}</p>

          <div className="flex gap-[30px] ">
            <a href={"/"} className="">
              <img
                src={"/asset/facebook.svg"}
                width={16}
                height={16}
                alt="icons"
              />
            </a>
            <a href={"/"} className="">
              <img
                src={"/asset/twitter.svg"}
                width={16}
                height={16}
                alt="icons"
              />
            </a>
            <a href={"/"} className="">
              <img
                src={"/asset/insta.svg"}
                width={16}
                height={16}
                alt="icons"
              />
            </a>
            <a href={"/"} className="">
              <img
                src={"/asset/linkedin.svg"}
                width={16}
                height={16}
                alt="icons"
              />
            </a>
          </div>
          <button className="px-[20px] py-[10px] bg-light-blue rounded-[10px] hover:bg-dark-blue transition ease duration-75">
            Hire Me
          </button>
        </div>
      ))}
    </section>
  );
};

export default UserDetail;
